<template>
    <div class="container-fluid bg-dark mt-0 text-light">
      <div class="row">
        <div class="col-12">
              <h1>Neotropical</h1>
              <p>Spanish made easy by Elsa and Alejandra.</p>
              <ul class="nav justify-content-center">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#about-us">About us</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#ex-students">Ex students</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#contact-us">Contact us</a>
                  </li>
                  
                </ul>
                <!-- <img class="img" width="100%" :src="require(`@/assets/banner.jpg`)" /> -->
                
            </div>
      </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-12 m-2">
              <h2 id="about-us">About us</h2>
              <p class="text-start">
                One to one and online Spanish lessons. Contact us and not only learn Spanish, but
Colombian culture.
We have been in Santa Marta since 2008. With our method, you can improve your
communication skills with an easy grammar approach.
You decide how many hours you want to study, we’ll guide you and make lessons based on
your needs.
              </p>
            </div>
            <div class="col-md-4">
                <h3>Aleja Alzate</h3>
                <img class="img" width="100%" :src="require(`@/assets/aleja.jpg`)" />
                <p>
                  <a href="mailto:alalzate91@gmail.com">alalzate91@gmail.com</a>
                </p>
            </div>
            <div class="col-md-4">
                <h3>Elsa Valencia</h3>
                <img class="img" width="100%" :src="require(`@/assets/elsa.jpg`)" />
                <p>
                  <a href="mailto:elsalivami@gmail.com">elsalivami@gmail.com</a>
                </p>
                <!-- <p class="text-start">
                  Elsa Valencia have an academic training in Spanish as a second language, management and a background as a chief secretary before she began teaching Spanish.

See video: <a href="http://youtu.be/CNYuYuSWpKI">http://youtu.be/CNYuYuSWpKI</a>
                </p> -->
                
            </div>
            <div class="col-md-8 col-12 mx-2 text-start">
              <h3 id="ex-students">Opinions from other students</h3>
              <ul>
                <li v-for="student in data" :key="student.slug">
                  <h4>{{student.title}}</h4>
                  <p v-html="student.content"></p>
                </li>
              </ul>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-dark pb-5 text-light">
      <div class="row">
        <div class="col-12 ">
                <h3 id="contact-us">Contact us</h3>
                <p>
                    Our other channels
                </p>
                <ul class="nav justify-content-center mt-2">
                  <li class="nav-item">
                    <a href="https://www.instagram.com/spanishbyalejandra/?hl=en" target="_blank" rel="noopener">Instagram</a>
                  </li>
                  <li class="nav-item">
                    <a href="mailto:alalzate91@gmail.com">alalzate91@gmail.com</a>
                  </li>
                  <li class="nav-item">
                    <a href="mailto:info@neotropical.se">info@neotropical.se</a>
                  </li>
                  <li class="nav-item">
                    <a href="https://www.facebook.com/Neotropicalspanish">Facebook</a>
                  </li>
                </ul>
            </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import {ref} from 'vue'
  import axios from 'axios'
  // export default {
  //   name: 'Page',
  //   props: {
  //     msg: String
  //   }
  // }
  const data = ref([]);
  axios.post('https://neotropical.se/cms/graphql', {
          query: "{posts(first:50,where:{categoryIn:3}){ nodes{id,guid,postId,title,slug,content,categories {nodes {id,name,categoryId}},featuredImage{node{id,sourceUrl}}} }}"
        }).then(function(response){
            console.log(response.data.data);
            data.value = response.data.data.posts.nodes;
        });
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .img{
    width:100%;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  